@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("roboto300.fbcb71ed.ttf") format("truetype");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("roboto400.8bedb902.ttf") format("truetype");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("roboto500.5c69b488.ttf") format("truetype");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("roboto500.5c69b488.ttf") format("truetype");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("roboto700.ab0444c7.ttf") format("truetype");
}

body {
  font-family: Roboto;
}
/*# sourceMappingURL=index.2a1b1f9d.css.map */
